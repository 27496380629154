<template>
 
  <div class="bg-grey-3">
      
    <!-- ======================== BERITA ========================  -->
    <div class="q-my-md q-py-lg q-px-md bg-amber-6 text-black" v-if="$q.screen.lt.sm">
      <div class="text-h6 text-weight-bolder">GALERI</div>
      <div class="text-h6 " style="font-size:15px">GALERI FOTO</div>
    </div>
    <div class="q-my-md q-py-lg q-px-md text-center bg-amber-6 text-black" v-if="$q.screen.sm || $q.screen.md || $q.screen.lg || $q.screen.xl">
      <div class="text-h6 text-weight-bolder">GALERI</div>
      <div class="text-h6 " style="font-size:15px">GALERI FOTO</div>
    </div>
    
    
    <div class="row wrap justify-between items-start content-start ">

      <div class="col-2"> </div>
      <div class="col-8 col-md-8 col-sm-12 col-xs-12 q-pa-md q-gutter-y-lg items-end">
          

        <div class="column items-end">
            <div class="col">
                <q-input rounded standout="bg-amber-12 text-black" bottom-slots v-model="cari_value" @keyup="cari_data()" label="Pencarian" style="width:328px" >
                    <template v-slot:prepend>
                        <q-icon color="black" name="search" />
                    </template>
                    <template v-slot:append>
                        <q-icon color="black" name="close" @click="cari_value = '', cari_data()" class="cursor-pointer" />
                    </template>
                </q-input>
            </div>
        </div>

 

    <div class="q-col-gutter-md row items-start">
      <div class="col-12 col-sm-12 col-md-4" v-for=" data in list_data " :key="data.index">
        <a href="javascript:void(0)" @click="selectData(data), modal = true">
          <q-img :src="$store.state.FILE_LOAD + data.gambar" :ratio="4/3" >
            <div class="absolute-bottom text-subtitle1 text-center" style="font-size:0.7em">
              {{data.judul}}
            </div>
          </q-img>
        </a>
      </div>
    </div>
    
          <!-- <q-card v-for=" data in list_data " :key="data.index" class="my-card" flat bordered clickable  >

            <q-card-section v-if="$q.screen.lt.sm"> 
              <q-card class="my-card">
                <img :src="$store.state.FILE_LOAD + data.gambar"> 
                <q-card-section>
                  <div class="text-label text-blue-grey" style="font-size:11px">       
                    <q-icon  :size="'xs'" name="mdi-pencil" /> {{data.createBy}} <br>
                    <q-icon  :size="'xs'" name="mdi-calendar-text" /> {{UMUM.tglConvert(data.createAt).tgl}} 
                  </div> 
                  <div class="text-uppercase" style="font-size:15px;"><b>{{data.judul}}</b></div> 
                <div><a href="javascript:void(0)" @click="push_pengumuman(data.id)" class="text-hampa text-blue-grey" style="font-size:11px"><i>Selengkapnya</i></a></div>
                </q-card-section> 
              </q-card>  
            </q-card-section>
            

            <q-card-section horizontal v-if="$q.screen.sm || $q.screen.md || $q.screen.lg || $q.screen.xl">
              <q-img class="" :src="$store.state.FILE_LOAD + data.gambar" height="180px" style="max-width: 150px" v-if="$q.screen.lt.sm" />
              <q-img class="" :src="$store.state.FILE_LOAD + data.gambar" height="180px" style="max-width: 150px" v-if="$q.screen.sm || $q.screen.md || $q.screen.lg || $q.screen.xl" />
              <q-card-section class="">
                <div class="text-label text-blue-grey" style="font-size:11px">       
                  <q-icon  :size="'xs'" name="mdi-pencil" /> {{data.createBy}} <br>
                  <q-icon  :size="'xs'" name="mdi-calendar-text" /> {{UMUM.tglConvert(data.createAt).tgl}} 
                </div>
                <div class="text-uppercase" style="font-size:15px;"><b>{{data.judul}}</b></div> 
                <div><a href="javascript:void(0)" @click="push_pengumuman(data.id)" class="text-hampa text-blue-grey" style="font-size:11px"><i>Selengkapnya</i></a></div>
              </q-card-section> 
            </q-card-section>
          </q-card> -->

          <div class="q-pa-lg flex flex-center">
            <q-btn @click="btn_prev" color="black" icon="mdi-skip-previous" />
            &nbsp;
            <span style="font-size: 85%; color: #a5a5a5">
                <b>{{page_first}} - {{page_last}}</b>
            </span>
            &nbsp;
            <q-btn @click="btn_next" color="black" icon="mdi-skip-next" />   
          </div>

         

      </div>
      <div class="col-2"></div> 

    <q-dialog v-model="modal">
      <q-card style="width:600px">
          <q-card-section class="bg-amber-8 row items-center q-pb-md">
          <!-- <div class="text-h6">{{judul}}</div> -->
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup />
          </q-card-section>

          <q-card-section>
              <div class="text-center">
                <b>
                  {{judul}} 
                </b>
                  <!-- <div class="text-h6">Close icon</div> -->
              </div>

              <q-img :src="$store.state.FILE_LOAD + gambar" :ratio="4/3" />
          </q-card-section>
      </q-card>
    </q-dialog>         
    </div>

    <!-- ======================== BERITA ========================  -->
    
  </div>     
</template>

<script> 

import rightbar from  "../../components/rightbar.vue"
import UMUM from  "../../library/umum"

export default {
  name: 'Home',
  components: {

    //   rightbar,

    
  },
  data() {
    return {
      list_data:[],
      UMUM  : UMUM,
      modal  : false,
      judul: "",
      gambar: "",
      
            // list_data: [],
            // list_kategori: [],

      page_first: 1,
      page_last: 0,
      cari_value: "",

      tabs:'mails',
      splitterModel:70,
      slide: 1,
      slide_pengumuman:'style',
      lorem:'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iusto, veniam! Quia rem cum at quae ad voluptate necessitatibus. Numquam id nam maxime amet vel, sunt ducimus recusandae eaque aliquam animi',
      contacts:'',
      offline:'',
      
      thumbStyle: {
        right: '4px',
        borderRadius: '5px',
        backgroundColor: '#027be3',
        width: '5px',
        opacity: 0.75
      },

      barStyle: {
        right: '2px',
        borderRadius: '9px',
        backgroundColor: '#027be3',
        width: '9px',
        opacity: 0.2
      }

    }
  },
  methods: {
    getView: function() {
        // this.cek_load_data = true;
        fetch(this.$store.state.URL_galeri_foto + "view", {
            method: "POST",
            headers: {
            "content-type": "application/json",
            // authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({
                kategori_id : this.kategori_id,
                unit_kerja: this.$store.state.UNIT_KERJA,
                data_ke: this.page_first,
                cari_value: this.cari_value
            })
        })
        .then(res => res.json())
        .then(res_data => {
            console.log(res_data);
            this.list_data = res_data.data;
            this.page_last = res_data.jml_data;
        // this.cek_load_data = false;
        });
    },  

    selectData: function (data){
      this.judul =  data.judul
      this.gambar = data.gambar
    },

    push_pengumuman : function(id){
      this.$router.push('/detile_pengumuman/'+id);
    },
 

  // ================== PAGINASI ====================
    alertku: function(type, title) {
      this.$swal({
        type: type,
        title: title,
        showConfirmButton: false,
        timer: 800
      });
    },

    btn_prev : function(){
            this.cek_load_data = true;
        if(this.page_first>1){
            this.page_first--
        }else{
            this.page_first = 1;
        }
        this.getView();
    },

    btn_next : function(){
        
        if(this.page_first >= this.page_last){
            this.page_first == this.page_last
        }else{
            this.page_first++;
        }
        this.getView();
    },

    cari_data : function(){

        this.page_first = 1;
        this.getView();
    },
    onFileSelected: function(event) {
      this.form.file = event.target.files[0];
    },
 
  // ================== PAGINASI ====================

 

  },
  mounted() { 
    this.getView()
  },
}
</script>

<style>
  .text-hampa{
    text-decoration: none;
  }
</style>