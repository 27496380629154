<template>
 
  <div class="bg-grey-3" >

    <div class="q-my-md q-py-lg q-px-md bg-amber-6 text-black" v-if="$q.screen.lt.sm">
      <div class="text-h6 text-weight-bolder">KEBIJAKAN</div>
      <!-- <div class="text-h6 " style="font-size:15px">BERITA TERBARU</div> -->
    </div>
    <div class="q-my-md q-py-lg q-px-md text-center bg-amber-6 text-black" v-if="$q.screen.sm || $q.screen.md || $q.screen.lg || $q.screen.xl">
      <div class="text-h6 text-weight-bolder">KEBIJAKAN</div>
      <!-- <div class="text-h6 " style="font-size:15px">BERITA TERBARU</div> -->
    </div>      
      
 
    <div class="row wrap justify-between items-start content-start ">

      <div class="col-2"> </div>
      <div class="col-8 col-md-8 col-sm-12 col-xs-12 q-pa-md q-gutter-y-lg items-end">
 
          <q-card class="my-card" flat bordered clickable >
            <q-card-section vertical>
              <q-card-section class="" v-for="n in list_data" :key="n.id">
                <!-- <q-img class="" src="https://cdn.quasar.dev/img/parallax2.jpg" style="max-width: 100%;width: 100%" /> -->
                <div class="" style=""> 
                    <div class="text-left text-weight-bold " style="font-size:18px">{{n.judul}} </div>
                    <q-separator class="q-my-md" />
                </div>
                <div style="font-size:14px;" v-html="n.isi"> </div>
                <q-separator class="q-mt-md" />
                <!-- <div class="text-label text-blue-grey" style="font-size:11px">
                  <q-icon  :size="'xs'" name="mdi-calendar-text" /> {{UMUM.tglConvert(n.createAt).tgl}}                    
                  <q-icon  :size="'xs'" name="mdi-pencil" /> {{n.createBy}}  
                </div> -->
                 
              </q-card-section> 
 
            </q-card-section>
          </q-card>

          

      </div>
      <div class="col-2"></div> 
    </div> 
    
  </div>     
</template>

<script> 

import rightbar from  "../../components/rightbar.vue"
import UMUM from  "../../library/umum"

export default {
  name: 'Home',
  components: {

    //   rightbar,

    
  },
  data() {
    return {
      list_data:[],
      UMUM:UMUM,
      
 

    }
  },

  methods: {
    getView: function() {
        // this.cek_load_data = true;
        fetch(this.$store.state.URL_perjanjian_kinerja + "view", {
            method: "POST",
            headers: {
            "content-type": "application/json",
            // authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({
                // id          : this.$route.params.id,
                // unit_kerja  : this.$store.state.UNIT_KERJA, 
            })
        })
        .then(res => res.json())
        .then(res_data => {
            console.log(res_data);
            this.list_data = res_data; 
        // this.cek_load_data = false;
        });
    },      
  },
  mounted() {
    this.getView()
  },
}
</script>

<style>
  .text-hampa{
    text-decoration: none;
  }
</style>