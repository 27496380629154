<template>
 
  <div class="bg-grey-3" >
      
 
    <div class="row wrap justify-between items-start content-start ">

      <div class="col-2"> </div>
      <div class="col-8 col-md-8 col-sm-12 col-xs-12 q-pa-md q-gutter-y-lg items-end">
 
          <q-card class="my-card" flat bordered clickable >
            <q-card-section vertical>
              <q-card-section class="">
                <q-img class="" :src="$store.state.FILE_LOAD + list_data.foto" style="max-width: 100%;width: 100%" />
                <div class="" style=""> 
                    <q-separator class="q-my-md" />
                    <div class="text-center text-weight-bold q-mt-md" style="font-size:18px">{{list_data.judul}} </div>
                    <q-separator class="q-my-md" />
                </div>
                <div style="font-size:14px;" v-html="list_data.isi"> </div>
                <div class="text-label text-blue-grey" style="font-size:11px">
                  <q-separator class="q-my-md" />
                  <q-icon  :size="'xs'" name="mdi-calendar-text" /> {{convert_tgl(list_data.createAt)}}
                  <q-icon  :size="'xs'" name="mdi-pencil" /> {{list_data.createBy}}  
                </div>
                 
              </q-card-section> 
 
            </q-card-section>
          </q-card>

          

      </div>
      <div class="col-2"></div> 
    </div> 
    
  </div>     
</template>

<script> 

import rightbar from  "../../components/rightbar.vue"

export default {
  name: 'Home',
  components: {

    //   rightbar,

    
  },
  data() {
    return {
      list_data:[],
      tabs:'mails',
      splitterModel:70,
      slide: 1,
      slide_pengumuman:'style',
      lorem:'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iusto, veniam! Quia rem cum at quae ad voluptate necessitatibus. Numquam id nam maxime amet vel, sunt ducimus recusandae eaque aliquam animi',
      contacts:'',
      offline:'',
      
      thumbStyle: {
        right: '4px',
        borderRadius: '5px',
        backgroundColor: '#027be3',
        width: '5px',
        opacity: 0.75
      },

      barStyle: {
        right: '2px',
        borderRadius: '9px',
        backgroundColor: '#027be3',
        width: '9px',
        opacity: 0.2
      }

    }
  },
  methods: {
    getView: function() {
        // this.cek_load_data = true;
        fetch(this.$store.state.URL_berita + "isi_berita", {
            method: "POST",
            headers: {
            "content-type": "application/json",
            // authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({
                id          : this.$route.params.id,
                unit_kerja  : this.$store.state.UNIT_KERJA, 
            })
        })
        .then(res => res.json())
        .then(res_data => {
            console.log(res_data[0]);
            this.list_data = res_data[0]; 
        // this.cek_load_data = false;
        });
    },
    convert_tgl: function(dateString) {
      var date = new Date(dateString);
      var getBulan = date.getMonth() + 1; var bulan = '';
      if (getBulan == '1') {bulan = 'Jan'} 
      else if(getBulan == '2') {bulan = 'Feb'}
      else if(getBulan == '3') {bulan = 'Mar'}
      else if(getBulan == '4') {bulan = 'Apr'}
      else if(getBulan == '5') {bulan = 'Mei'}
      else if(getBulan == '6') {bulan = 'Jun'}
      else if(getBulan == '7') {bulan = 'Jul'}
      else if(getBulan == '8') {bulan = 'Agt'}
      else if(getBulan == '9') {bulan = 'Sep'}
      else if(getBulan == '10') {bulan = 'Okt'}
      else if(getBulan == '11') {bulan = 'Nov'}
      else if(getBulan == '12') {bulan = 'Des'}



      return date.getDate() + " " + bulan + " " + date.getFullYear();
    // return bulan+ " " + date.getDate() + ". " + date.getFullYear();
    },      
  },
  mounted() {
    this.getView()
  },
}
</script>

<style>
  .text-hampa{
    text-decoration: none;
  }
</style>