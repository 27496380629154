import { createStore } from 'vuex'

var URL = 'https://server-web.konaweselatankab.go.id/' 

export default createStore({
  state: {

    URLX : URL,

    // home 
    FILE_LOAD                                : URL + 'uploads/',
    URL_slider                               : URL + 'api/v1/publish_pk_slider/',
    URL_berita                               : URL + 'api/v1/publish_pk_berita/',
    URL_detile                               : URL + 'api/v1/',

    URL_gambaran_umum                        : URL + 'api/v1/publish_pk_gambaran_umum/',
  //URL_ Profile 
    URL_visi_misi                            : URL + 'api/v1/publish_pk_visimisi/',
    URL_struktur_organisasi                  : URL + 'api/v1/publish_pk_struktur_organisasi/',
    URL_rencana_strategis                    : URL + 'api/v1/publish_pk_rencana_strategis/',
    URL_rencana_kerja                        : URL + 'api/v1/publish_pk_rencana_kerja/',
    URL_perjanjian_kinerja                   : URL + 'api/v1/publish_pk_perjanjian_kinerja/',
    URL_laporan_kinerja                      : URL + 'api/v1/publish_pk_laporan_kinerja/',
    URL_indikator_kinerja_utama              : URL + 'api/v1/publish_pk_indikator_kinerja_utama/',
    URL_laporan_kinerja_instansi_pemerintah  : URL + 'api/v1/publish_pk_lkjip/',
  //URL_ Informasi Publik
    URL_standar_operasional_prosedur         : URL + 'api/v1/publish_pk_sop/',
    URL_daftar_layanan                       : URL + 'api/v1/publish_pk_daftar_layanan/',
    URL_pengumuman                           : URL + 'api/v1/publish_pk_pengumuman/',
    URL_peraturan                            : URL + 'api/v1/publish_pk_peraturan/',
    URL_data                                 : URL + 'api/v1/publish_pk_data/',

    URL_galeri_foto                          : URL + 'api/v1/publish_pk_galeri_foto/',
    URL_galeri_video                         : URL + 'api/v1/publish_pk_galeri_video/',

    URL_kontak                               : URL + 'api/v1/publish_pk_kontak/',
    URL_sambutan                             : URL + 'api/v1/publish_pk_sambutan/',
    URL_link_terkait                         : URL + 'api/v1/web_publish_link_terkait/',

    UNIT_KERJA                               :'kxFjL7W84BoY5PM3P',
    

  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
