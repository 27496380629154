<template>
  <q-layout view="hHh lpR fFf">

    <sidebarnya/>

    <q-page-container>
      <!-- <HelloWorld/> -->
      <router-view />
    </q-page-container>


    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 bg-amber ">      
        <div class="q-pa-md">
          <div class="text-center text-overline">@Diskominfo & Sandi Konsel</div>
        </div>
        
      </div>
    </div>    

  

  </q-layout>
</template>

<script>

import sidebarnya from "./components/sidebar.vue";

export default {
  name: 'LayoutDefault',
  components: {
    sidebarnya,
  },  

}
</script>

 

 