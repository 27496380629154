import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import berita from '../views/berita/berita.vue'
import detile from '../views/berita/detile.vue'

import pengumuman from '../views/pengumuman/pengumuman.vue'
import detile_pengumuman from '../views/pengumuman/detile_pengumuman.vue'

import profil_dinas from '../views/profile/profil_dinas.vue'
import tujuan from '../views/profile/tujuan.vue'
import struktur_organisasi from '../views/profile/struktur_organisasi.vue'
import strategi from '../views/profile/strategi.vue'
import program from '../views/profile/program.vue'
import kebijakan from '../views/profile/kebijakan.vue'

import regulasi from '../views/informasi_publik/regulasi.vue'
import detail_regulasi from '../views/informasi_publik/detail_regulasi.vue'
import data_dokumen from '../views/informasi_publik/data_dokumen.vue'
import detail_dokumen from '../views/informasi_publik/detail_dokumen.vue'

import foto from '../views/galeri/foto.vue'
import video from '../views/galeri/video.vue'
  // Profile 
    // visi_misi
    // struktur_organisasi
    // rencana_strategis
    // rencana_kerja
    // perjanjian_kinerja
    // laporan_kinerja
    // indikator_kinerja_utama
    // laporan_kinerja_instansi_pemerintah
  // Informasi Publik
    // standar_operasional_prosedur
    // daftar_layanan
    // pengumuman  

  


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/berita',
    name: 'berita',
    component: berita
  },
  {
    path: '/detile/:id',
    name: 'detile',
    component: detile
  },
  {
    path: '/pengumuman',
    name: 'pengumuman',
    component: pengumuman
  },
  {
    path: '/detile_pengumuman/:id',
    name: 'detile_pengumuman',
    component: detile_pengumuman
  },
  // PROFILE 
    {
      path: '/profil_dinas',
      name: 'profil_dinas',
      component: profil_dinas
    },
    {
      path: '/tujuan',
      name: 'tujuan',
      component: tujuan
    },
    {
      path: '/struktur_organisasi',
      name: 'struktur_organisasi',
      component: struktur_organisasi
    },
    {
      path: '/strategi',
      name: 'strategi',
      component: strategi
    },
    {
      path: '/kebijakan',
      name: 'kebijakan',
      component: kebijakan  
    },
    {
      path: '/program',
      name: 'program',
      component: program
    },
    
  // INFORMASI PUBLIK 
    {
      path: '/regulasi',
      name: 'regulasi',
      component: regulasi
    },
    {
    path: '/detail_regulasi/:id',
    name: 'detail_regulasi',
    component: detail_regulasi
    },
    {
      path: '/data_dokumen',
      name: 'data_dokumen',
      component: data_dokumen
    },
    {
      path: '/detail_dokumen/:id',
      name: 'detail_dokuumen',
      component: detail_dokumen
      },
  // GALERI 
    {
      path: '/foto',
      name: 'foto',
      component: foto
    },
    {
      path: '/video',
      name: 'video',
      component: video
    },

  {
    path: '/:catchAll(.*)*',
    component: () => import('../views/error.vue')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
