<template>
 
  <div class="">
    <!-- ======================== SLIDER SIZE ANDROID ========================  -->
 
    <q-responsive  v-if="$q.screen.lt.sm" :ratio="16/9" style="width: 500px; max-width: 100%;">
      <q-carousel swipeable animated :autoplay="true" arrows v-model="slide" infinite >
        <q-carousel-slide v-for="n in this.list_slide" :key="n.index" :name="n.id" :img-src="$store.state.FILE_LOAD+n.file" />
      </q-carousel>
    </q-responsive>
    
    <!-- ======================== SLIDER SIZE ANDROID ========================  -->

    <!-- ======================== SLIDER SIZE PC ========================  -->
  
      <q-carousel v-if="$q.screen.sm || $q.screen.md || $q.screen.lg || $q.screen.xl" class="q-mt-xs" swipeable animated :autoplay="true" arrows v-model="slide" infinite >
        <q-carousel-slide v-for="n in this.list_slide" :key="n.index" :name="n.id" :img-src="$store.state.FILE_LOAD+n.file" />
      </q-carousel>    
 
    <!-- ======================== SLIDER SIZE PC ========================  -->

    <!-- ======================== Sambutan ========================  -->
    <div class="q-my-md q-py-lg q-px-md bg-amber-6 text-black" v-if="$q.screen.lt.sm">
      <div class="text-h6 text-weight-bolder">SAMBUTAN</div>
      <div class="text-h6 " style="font-size:15px">KEPALA DINAS</div>
    </div>
    <div class="q-my-md q-py-lg q-px-md text-center bg-amber-6 text-black" v-if="$q.screen.sm || $q.screen.md || $q.screen.lg || $q.screen.xl">
      <div class="text-h6 text-weight-bolder">SAMBUTAN</div>
      <div class="text-h6 " style="font-size:15px">KEPALA DINAS</div>
    </div>
    <div class="q-pa-md">

    <div class="row">
      <div class="col">
        <div class="q-px-lg">
        <q-img :src="$store.state.FILE_LOAD+sambutan_foto" spinner-color="white"
      style="height: 350px; max-width: 100%"
      img-class="my-custom-image"
      class="rounded-borders">
          <div class="absolute-bottom-right text-subtitle2">
            {{sambutan_judul}}
          </div>
        </q-img>
      </div>
      </div>
      <div class="col">
        <div class="q-px-lg">
        <p v-html="sambutan_isi"></p>
        </div>
      </div>
    </div>
    </div>

    <!-- ======================== BERITA ========================  -->
    <div class="q-my-md q-py-lg q-px-md bg-amber-6 text-black" v-if="$q.screen.lt.sm">
      <div class="text-h6 text-weight-bolder">BERITA</div>
      <div class="text-h6 " style="font-size:15px">BERITA TERBARU</div>
    </div>
    <div class="q-my-md q-py-lg q-px-md text-center bg-amber-6 text-black" v-if="$q.screen.sm || $q.screen.md || $q.screen.lg || $q.screen.xl">
      <div class="text-h6 text-weight-bolder">BERITA</div>
      <div class="text-h6 " style="font-size:15px">BERITA TERBARU</div>
    </div>
    
    
    <div class="row wrap justify-between items-start content-start">
      
      <div class="col q-pa-md q-gutter-y-lg">
         

          <q-card v-for="n in list_berita " :key="n.index" class="my-card shadow-2" flat bordered clickable >


            <q-card-section class="shadow-2" v-if="$q.screen.lt.sm">
              <q-img class="" :src="$store.state.FILE_LOAD+n.file" />
              <q-card-section class="">
                <div class="text-label text-blue-grey" style="font-size:11px">       
                  <q-icon  :size="'xs'" name="mdi-calendar-text" /> {{convert_tgl(n.createAt)}} <br>
                  <q-icon  :size="'xs'" name="mdi-pencil" /> {{n.createBy}} 
                </div>
                <div class="text-uppercase" style="font-size:15px;"><b>{{n.judul}}</b></div>
                <div style="font-size:13px;">{{n.deskripsi}}</div>
                <div><a href="javascript:void(0)" @click="push_berita(n.id)" class="text-hampa text-blue-grey" style="font-size:11px"><i>Selengkapnya</i></a></div>
              </q-card-section> 
            </q-card-section>

            <q-card-section class="shadow-2" horizontal v-if="$q.screen.sm || $q.screen.md || $q.screen.lg || $q.screen.xl">
              <q-img class="" :src="$store.state.FILE_LOAD+n.foto" style="max-width: 200px" />
              <q-card-section class="">
                <div class="text-label text-blue-grey" style="font-size:11px">       
                  <q-icon  :size="'xs'" name="mdi-calendar-text" /> {{convert_tgl(n.createAt)}} <br>
                  <q-icon  :size="'xs'" name="mdi-pencil" /> {{n.createBy}} 
                </div>
                <div class="text-uppercase" style="font-size:15px;"><b>{{n.judul}}</b></div>
                <div style="font-size:13px;">{{n.deskripsi}}</div>
                <div><a href="javascript:void(0)" @click="push_berita(n.id)" class="text-hampa text-blue-grey" style="font-size:11px"><i>Selengkapnya</i></a></div>
              </q-card-section>


            </q-card-section>
          </q-card>

      </div>
    </div>
    <!-- ======================== PENGUMUMAN ========================  -->
    <div class="q-my-md q-py-lg q-px-md bg-amber-6 text-black" v-if="$q.screen.lt.sm">
      <div class="text-h6 text-weight-bolder">INFORMASI PUBLIK</div>
      <div class="text-h6 " style="font-size:15px">PENGUMUMAN</div>
    </div>
    <div class="q-my-md q-py-lg q-px-md text-center bg-amber-6 text-black" v-if="$q.screen.sm || $q.screen.md || $q.screen.lg || $q.screen.xl">
      <div class="text-h6 text-weight-bolder">INFORMASI PUBLIK</div>
      <div class="text-h6 " style="font-size:15px">PENGUMUMAN</div>
    </div>

    <q-scroll-area style="height: 170px; max-width: 100%;">
      <div class="row no-wrap">
        <div v-for="n in list_pengumuman" :key="n" style="width: 300px" class="q-pa-sm col">
          <q-card class="my-card"> 
            <q-card-section>
              <div class="text-label text-blue-grey" style="font-size:11px">
                <q-icon  :size="'xs'" name="mdi-calendar-text" /> {{convert_tgl(n.createAt)}} <br>
                <q-icon  :size="'xs'" name="mdi-pencil" /> {{n.createBy}}
              </div> 
              <div class="" style="font-size:14px"> <b>{{n.judul}}</b> </div>
              <div>
                <a href="javascript:void(0)" @click="push_pengumuman(n.id)" class="text-hampa text-blue-grey" style="font-size:12px" ><i>Baca Selengkapnya..</i></a>              
              </div>
            </q-card-section>

            <q-card-section class="q-pt-none text-justify text-body2">

            </q-card-section>
          </q-card>
        </div>
      </div>
    </q-scroll-area>    
    <div class="col self-center">
          <center><router-link class="q-pa-lg text-hampa" to="/pengumuman">
              <q-btn padding="md" color="amber-8" round icon="mdi-chevron-double-right" />
          </router-link>
          </center>

        </div>

 

   
    <!-- ======================== PENGUMUMAN ========================  -->

    <!-- ======================== PENGUMUMAN ========================  -->
    <div class="q-my-md q-py-lg q-px-md bg-amber-6 text-black" v-if="$q.screen.lt.sm">
      <div class="text-h6 text-weight-bolder">GALERI</div>
      <div class="text-h6 " style="font-size:15px">FOTO TERBARU</div>
    </div>
    <div class="q-my-md q-py-lg q-px-md text-center bg-amber-6 text-black" v-if="$q.screen.sm || $q.screen.md || $q.screen.lg || $q.screen.xl">
      <div class="text-h6 text-weight-bolder">GALERI</div>
      <div class="text-h6 " style="font-size:15px">FOTO TERBARU</div>
    </div>

    <div class="q-pa-md">

    <div class="row">
      <div class="col" v-for=" data in list_foto " :key="data">
        <div class="q-px-lg">
          <a href="javascript:void(0)" @click="selectFoto(data), modal = true">
        <q-img :src="$store.state.FILE_LOAD + data.gambar" spinner-color="white"
      style="height: 350px; max-width: 100%"
      img-class="my-custom-image"
      class="rounded-borders">
          <div class="absolute-bottom-right text-subtitle2">
            {{data.judul}}
          </div>
        </q-img>
          </a>
      </div>
      </div>
    </div>
    </div>   
    <q-dialog v-model="modal">
      <q-card style="width:600px">
          <q-card-section class="bg-amber-8 row items-center q-pb-md">
          <!-- <div class="text-h6">{{judul}}</div> -->
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup />
          </q-card-section>

          <q-card-section>
              <div class="text-center">
                <b>
                  {{judul}} 
                </b>
                  <!-- <div class="text-h6">Close icon</div> -->
              </div>

              <q-img :src="$store.state.FILE_LOAD + gambar" style="width:100%;" />
          </q-card-section>
      </q-card>
    </q-dialog>     

    <!-- ======================== PENGUMUMAN ========================  -->
    <div class="q-my-md q-py-lg q-px-md bg-amber-6 text-black" v-if="$q.screen.lt.sm">
      <div class="text-h6 text-weight-bolder">LINK TERKAIT</div>
      <!-- <div class="text-h6 " style="font-size:15px">FOTO TERBARU</div> -->
    </div>
    <div class="q-my-md q-py-lg q-px-md text-center bg-amber-6 text-black" v-if="$q.screen.sm || $q.screen.md || $q.screen.lg || $q.screen.xl">
      <div class="text-h6 text-weight-bolder">LINK TERKAIT</div>
      <!-- <div class="text-h6 " style="font-size:15px">FOTO TERBARU</div> -->
    </div>

    <div class="q-pa-md">

    <div class="row">
      <div class="col" v-for="x in list_linkTerkait" :key="x.id">
        <div class="q-px-lg">
          <a :href="x.url" target = "_blank">
        <q-img :src="$store.state.FILE_LOAD + x.file" spinner-color="white"
      style="height: 100px; width: 200px"
      img-class="my-custom-image"
      class="rounded-borders" />
          <q-tooltip> {{x.deskripsi}} </q-tooltip>
          </a>
      </div>
      </div>
    </div>
    </div>   
     <!-- ======================== BERITA ========================  -->

 




    <!-- <img alt="Quasar logo" src="../assets/logo.svg" style="width: 200px; height: 200px"> -->
  </div>     
</template>

<script> 
import rightbar from  "../components/rightbar.vue"
export default {
  name: 'Home',
  components: {
    
  },
  data() {
    return {
      list_slide:[],
      list_foto:[],
      list_pengumuman:[],
      list_berita:[],
      list_kontak:[],
      list_linkTerkait:[],
      sambutan_nama: "",
      sambutan_foto: "",
      sambutan_isi: "",
      modal  : false,
      judul : "",
      gambar : "",


      unit_kerja:'kxFjL7W84BoY5PM3P',

      tabs:'mails',
      splitterModel:70,
      slide: 1,
      slide_pengumuman:'style',
      // lorem:'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iusto, veniam! Quia rem cum at quae ad voluptate necessitatibus. Numquam id nam maxime amet vel, sunt ducimus recusandae eaque aliquam animi',
      contacts:'',
      offline:'',
      
      thumbStyle: {
        right: '4px',
        borderRadius: '5px',
        backgroundColor: '#027be3',
        width: '5px',
        opacity: 0.75
      },

      barStyle: {
        right: '2px',
        borderRadius: '9px',
        backgroundColor: '#027be3',
        width: '9px',
        opacity: 0.2
      }

    }
  },
  methods: {
    slider:function(){
      this.$store.state.FILE_LOAD
      this.$store.state.URL_slider
      this.$store.state.URL_berita
      this.$store.state.URL_sambutan
      this.$store.state.URL_galeri_foto
      
    },
    slides: function() {
        this.cek_load_data = true;
      fetch(this.$store.state.URL_slider + "list", {
        method: "GET",
 
 
      })
        .then(res => res.json())
        .then(res_data => {
            this.list_slide = res_data;  
        });
    },    
    pengumuman: function() {
        this.cek_load_data = true;
      fetch(this.$store.state.URL_pengumuman + "list", {
        method: "GET", 
      })
        .then(res => res.json())
        .then(res_data => {
            this.list_pengumuman = res_data;   
        });
    },

    foto: function() {
        this.cek_load_data = true;
      fetch(this.$store.state.URL_galeri_foto + "list", {
        method: "GET", 
      })
        .then(res => res.json())
        .then(res_data => {
            this.list_foto = res_data;   
        });
    },

    sambutan: function() {
        this.cek_load_data = true;
      fetch(this.$store.state.URL_sambutan + "list", {
        method: "GET", 
      })
        .then(res => res.json())
        .then(res_data => {
            var sambutan = res_data[0];

                        this.sambutan_judul = sambutan.judul;
                        this.sambutan_isi = sambutan.isi; 
                        this.sambutan_foto = sambutan.gambar;
        });
    },

    selectFoto: function (data){
      this.judul =  data.judul
      this.gambar = data.gambar
    },

    berita: function() {
        this.cek_load_data = true;
      fetch(this.$store.state.URL_berita + "homeOPD", {
        method: "POST", 
        headers: {
          "content-type": "application/json",
          // authorization: "kikensbatara " + localStorage.token
        },        
        body: JSON.stringify({
            unit_kerja: this.unit_kerja
        })        
      })
        .then(res => res.json())
        .then(res_data => {
            this.list_berita = res_data;  
            // console.log(res_data);
        });
    },

    kontaks: function() {
        this.cek_load_data = true;
      fetch(this.$store.state.URL_kontak + "view", {
        method: "POST", 
        headers: {
          "content-type": "application/json",
          // authorization: "kikensbatara " + localStorage.token
        },        
        body: JSON.stringify({
            unit_kerja: this.unit_kerja
        })        
      })
        .then(res => res.json())
        .then(res_data => {
            this.list_kontak = res_data;  
            // console.log(res_data);
        });
    },
    linkterkait: function() {
        this.cek_load_data = true;
      fetch(this.$store.state.URL_link_terkait, {
        method: "POST", 
        headers: {
          "content-type": "application/json",
          // authorization: "kikensbatara " + localStorage.token
        },        
        body: JSON.stringify({
            unit_kerja: this.unit_kerja
        })        
      })
        .then(res => res.json())
        .then(res_data => {
            this.list_linkTerkait = res_data;  
            console.log(res_data);
        });
    },

    push_berita : function(id){
      this.$router.push('/detile/'+id);
    },    
    push_pengumuman : function(id){
      this.$router.push('/detile_pengumuman/'+id);
    },

    convert_tgl: function(dateString) {
      var date = new Date(dateString);
      var getBulan = date.getMonth() + 1; var bulan = '';
      if (getBulan == '1') {bulan = 'Jan'} 
      else if(getBulan == '2') {bulan = 'Feb'}
      else if(getBulan == '3') {bulan = 'Mar'}
      else if(getBulan == '4') {bulan = 'Apr'}
      else if(getBulan == '5') {bulan = 'Mei'}
      else if(getBulan == '6') {bulan = 'Jun'}
      else if(getBulan == '7') {bulan = 'Jul'}
      else if(getBulan == '8') {bulan = 'Agt'}
      else if(getBulan == '9') {bulan = 'Sep'}
      else if(getBulan == '10') {bulan = 'Okt'}
      else if(getBulan == '11') {bulan = 'Nov'}
      else if(getBulan == '12') {bulan = 'Des'}



      return date.getDate() + " " + bulan + " " + date.getFullYear();
    // return bulan+ " " + date.getDate() + ". " + date.getFullYear();
    },


    limitText:function(katas) { 
      var length = 255;
      var trimmedString = katas.substring(0, length);
      return {trimmedString} 
    },
    // slides: function() {
    //     this.cek_load_data = true;
    //   fetch(this.$store.state.URL_slider + "list/", {
    //     method: "GET",
    //     headers: {
    //       "content-type": "application/json",
    //       // authorization: "kikensbatara " + localStorage.token
    //     },
    //     body: JSON.stringify({
    //         // unit_kerja: this.unit_kerja,
    //         // data_ke: this.page_first,
    //         // cari_value: this.cari_value
    //     })
    //   })
    //     .then(res => res.json())
    //     .then(res_data => {
    //         this.list_slide = res_data; 
    //         console.log(this.list_slide);
    //     });
    // },    
  },
  mounted() {
    this.slides()
    this.pengumuman()
    this.foto()
    this.sambutan()
    this.berita()
    this.kontaks()
    this.linkterkait()
  },
}
</script>

<style>
  .text-hampa{
    text-decoration: none;
  }
</style>