import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'

// import Vue from 'vue'
// import rightbar from  "./components/rightbar.vue"
// Vue.component('rightbar', rightbar)




createApp(App)
.use(Quasar, quasarUserOptions)
.use(store)
.use(router) 
.mount('#app')
