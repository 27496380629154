<template>
    <div>
    <q-header elevated class="q-py-sm bg-amber text-black">
      <q-toolbar>
        <q-toolbar-title> 
          <q-img class="q-ma-sm" src="konsel.png" style="height: auto; max-width: 35px" />
          <span class="text-weight-bold text-uppercase" style="font-size:15px">
            Dinas Pendidikan Dan Kebudayaan
          </span>
        </q-toolbar-title> 

        <div v-if="$q.screen.sm || $q.screen.md || $q.screen.lg || $q.screen.xl"> 
          <q-tabs v-model="tab" inline-label class="text-black "  >
            <q-route-tab class="text-black " name="Beranda" label="Beranda" to="/" /> 
            <q-route-tab class="text-black " name="Berita" label="Berita" to="/berita" /> 
            <!-- <q-route-tab class="text-black " name="Profile" label="Profile" to="/about" />  -->
            <q-btn-dropdown class="text-black " auto-close stretch flat label="Profile">
              <q-list>
                <q-item to="/profil_dinas" clickable >
                  <q-item-section>Profil Dinas</q-item-section>
                </q-item>
                <q-item to="/tujuan" clickable >
                  <q-item-section>Tujuan</q-item-section>
                </q-item>
                <q-item to="/strategi" clickable >
                  <q-item-section>Strategi</q-item-section>
                </q-item>
                <q-item to="/Kebijakan" clickable >
                  <q-item-section>Kebijakan</q-item-section>
                </q-item>
                <q-item to="/program" clickable >
                  <q-item-section>Program</q-item-section>
                </q-item>
                <q-item to="/struktur_organisasi" clickable >
                  <q-item-section>Struktur Organisasi</q-item-section>
                </q-item>
              </q-list>
            </q-btn-dropdown>
            <q-item to="/pengumuman" clickable >
                  <q-item-section>PENGUMUMAN</q-item-section>
                </q-item> 
            <q-btn-dropdown class="text-black " auto-close stretch flat label="Unduhan">
              <q-list>
                <q-item to="/regulasi" clickable >
                  <q-item-section>Regulasi</q-item-section>
                </q-item> 
                <q-item to="/data_dokumen" clickable >
                  <q-item-section>Data & Dokumen</q-item-section>
                </q-item> 
              </q-list>
            </q-btn-dropdown>
            <q-btn-dropdown class="text-black " auto-close stretch flat label="Galeri">
              <q-list>
                <q-item to="/foto" clickable >
                  <q-item-section>Foto</q-item-section>
                </q-item> 
                <q-item to="/video" clickable >
                  <q-item-section>Video</q-item-section>
                </q-item> 
              </q-list>
            </q-btn-dropdown>
            
            <!-- <q-route-tab class="text-black " name="Beranda" label="Beranda" to="/" />  -->
          </q-tabs>          
        </div>
        

        <q-btn v-if="$q.screen.lt.sm" dense flat round icon="menu" @click="rightDrawerOpen = !rightDrawerOpen"  />
      </q-toolbar>
    </q-header>

    <!-- <q-drawer v-model="rightDrawerOpen" side="right" overlay behavior="mobile" elevated>
        <q-scroll-area class="fit">  -->
          
              <!-- <q-list>
                <q-item to="/gambaran_umum" clickable >
                  <q-item-section>Gambaran Umum</q-item-section>
                </q-item>
                <q-item to="/visimisi" clickable >
                  <q-item-section>Visi Misi</q-item-section>
                </q-item>
                <q-item to="/struktur_organisasi" clickable >
                  <q-item-section>Struktur Organisasi</q-item-section>
                </q-item>
                <q-item to="/rencana_strategis" clickable >
                  <q-item-section>Rencana Strategis</q-item-section>
                </q-item>
                <q-item to="/rencana_kerja" clickable >
                  <q-item-section>Rencana Kerja</q-item-section>
                </q-item>
                <q-item to="/perjanjian_kerja" clickable >
                  <q-item-section>Perjanjian Kinerja</q-item-section>
                </q-item>
                <q-item to="/laporan_kinerja" clickable >
                  <q-item-section>Laporan Kinerja</q-item-section>
                </q-item>
                <q-item to="/indikator_kinerja_utama" clickable >
                  <q-item-section>Indikator Kinerja Utama (IKU)</q-item-section>
                </q-item>
                <q-item to="/lkjip" clickable >
                  <q-item-section>Laporan Kinerja Instansi Pemerintah</q-item-section>
                </q-item>
              </q-list>     -->

 
        <!-- </q-scroll-area>      
    </q-drawer>         -->
    </div>
</template>

<script>
 
export default {
    name: "sidebarnya",
    
//   setup () {
//     const rightDrawerOpen = ref(false)

//     return {
//       rightDrawerOpen,
//       toggleRightDrawer () {
//         rightDrawerOpen.value = !rightDrawerOpen.value
//       }
//     }
//   },

  data() {
    return {
      rightDrawerOpen:false,
      tab:'Beranda',
      link :'inbox',
    }
  },
  methods: {
 
  },
}
</script>